import { LOGIN_ACTIONS } from "../constants/action-types";
import axios from "axios"

export const login = (email) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING});
    await axios
      .post("/1.0.0/users/login", {email: email})
      .then((response) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

// export const logout = () => {
//   return (dispatch) => {
//     dispatch({ type: LOGIN_ACTIONS.LOGOUT });
//   };
// };
