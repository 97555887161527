import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "../reducers/login";
import usersReducer from "../reducers/users";
import { LOGIN_ACTIONS } from "../constants/action-types";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "usersReducer",
  ],
};

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state.usersReducer = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  loginReducer,
  usersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
