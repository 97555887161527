import axios from "axios";
import { USERS_ACTIONS } from "../constants/action-types";

export const getUserByEmail = (email) => {
    return async(dispatch)=>{
        dispatch({type:USERS_ACTIONS.USERS_LOADING})
    await axios
    .post("1.0.0/users/email-login/", email)
    .then((response)=>{
        dispatch({type: USERS_ACTIONS.USERS_LOADED})
    })
    .catch((err)=>{
        console.log(err)
        dispatch({
            type:USERS_ACTIONS.USERS_ERROR,
            payload: err.response?.data
        })
    })
    }
};

export const getAllUsers = () => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_LOADING });
    await axios
      .get("/1.0.0/users", {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USERS_LOADED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const saveImportedUsers = (saveObject) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_REGISTER_START });
    await axios
      .post("/1.0.0/users/import", saveObject)
      .then((response) => {
        dispatch({ type: USERS_ACTIONS.USER_REGISTER_SUCCESS });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: USERS_ACTIONS.USER_REGISTER_ERROR,
          payload: err.response?.data,
        });
      });
  };
};

export const addImportErrors = (errors) => {
  return (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_IMPORT_ERRORS_ADD, payload: errors });
  };
};

export const emptyImportErrors = () => {
  return (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USER_IMPORT_ERRORS_EMPTY });
  };
};
