import React, { Component } from "react";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Upload, Modal } from "antd";
import XLSX from "xlsx";
import {
  addImportErrors,
  getAllUsers,
  saveImportedUsers,
  emptyImportErrors,
} from "../../actions/users";
import Axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {
    saveImportedUsers: (saveObj) => dispatch(saveImportedUsers(saveObj)),
    getAllUsers: () => dispatch(getAllUsers()),
    addImportErrors: (errors) => dispatch(addImportErrors(errors)),
    emptyImportErrors: () => dispatch(emptyImportErrors()),
  };
};
class ConnectedWizardExcelImport extends Component {
  state = {
    users: [],
    newUsers: [],
    file: [],
    emptyFirstName: [],
    emptyLastName: [],
    badEmail: [],
    duplicatedEmail: [],
  };

  componentDidMount = async () => {
    await this.props.getAllUsers();
    if (this.props.status) {
      this.setState({ users: this.props.users });
    }
  };

  removeFile = () => {
    this.setState({
      file: [],
      newUsers: [],
      emptyFirstName: [],
      emptyLastName: [],
      badEmail: [],
      duplicatedEmail: [],
    });
  };

  beforeUpload = async (file) => {
    const workbook = XLSX.read(await file.arrayBuffer(), { type: "buffer" });
    const sheet_name_list = workbook.SheetNames;
    const result = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );
    // console.log(result);

    let usersToSave = [result];
    const emails = result.map((email) => {
      return (
        "E-Mail",
        {
          email,
        }
      );
    });
    const firstnames = result.map((firstName) => {
      return (
        "First",
        {
          firstName,
        }
      );
    });
    const lastnames = result.map((lastName) => {
      return (
        "Name",
        {
          lastName,
        }
      );
    });
    for (const user in result) {
      let userToSave = {};
      emails.forEach((email) => {
        if (result[user][email]) {
          userToSave.email = result[user][email];
        }
      });
      firstnames.forEach((firstName) => {
        if (result[user][firstName]) {
          userToSave.firstName = result[user][firstName];
        }
      });
      lastnames.forEach((lastName) => {
        if (result[user][lastName]) {
          userToSave.lastName = result[user][lastName];
        }
      });
      usersToSave = [...result, userToSave];
    }

    this.setState({
      users: [...this.state.users, ...usersToSave],
      newUsers: [...usersToSave],
      file: [file],
    });
    console.log(this.state.newUsers);
    return false;
  };

  importData = async () => {
    await this.saveUsers();
  };

  saveUsers = async () => {
    const saveObj = {
      users: this.state.newUsers,
    };
    if (this.state.users.length === 0) {
    } else {
      await Axios.post("/1.0.0/users/import", saveObj)
        .then((response) => {
          const duplicatedInSystem = response.data.filter(
            (user) => user.duplicate && user.duplicate === true
          );
          if (this.state.newUsers.length - duplicatedInSystem.length > 0) {
            message.success("Erfolg", {
              name: this.state.file[0].name,
              number: this.state.newUsers.length - duplicatedInSystem.length,
            });
          } else {
            message.error(
              "Importfehler, wahrscheinlich existierten bereits einige E-Mails"
            );
          }
          this.props.emptyImportErrors();
          this.props.addImportErrors({
            badEmail: this.state.badEmail,
            emptyFirstName: this.state.emptyFirstName,
            emptyLastName: this.state.emptyLastName,
            duplicatedInSystem: duplicatedInSystem,
            duplicatedEmail: this.state.duplicatedEmail,
          });
        })
        .catch((err) => {
          console.log(err);
          if (this.props.message && this.props.message.code === 1003) {
            message.error(
              `E-Mail ist bereits vorhanden ${this.props.message.data.email}!`
            );
          } else {
            message.error("Importfehler");
          }
        });
    }
  };

  render() {
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
    return (
      <div>
        <Modal
          title={null}
          visible={true}
          footer={null}
          closable={false}
          width={430}
          height={405}
          centered
          style={{
            marginRight: "20px",
            animationDelay: this.state.animationDelay,
          }}
          forceRender
          bodyStyle={{ height: "300px" }}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ margin: "1em", textAlign: "left" }}>
              <Upload.Dragger
                name="file"
                multiple={false}
                onRemove={this.removeFile}
                accept={".xlsx"}
                beforeUpload={this.beforeUpload}
                fileList={this.state.file}
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: false,
                }}
                customRequest={dummyRequest}
              >
                <p
                  className="ant-upload-drag-icon"
                  style={{ textAlign: "center" }}
                >
                  <PlusOutlined />
                </p>
                <p
                  className="ant-upload-text"
                  style={{ color: "#00b7ae", textAlign: "center" }}
                >
                  Hochladen
                </p>
              </Upload.Dragger>
            </div>
            {this.state.newUsers.length === 0 &&
              this.state.file.length !== 0 && (
                <div style={{ color: "#ff4d4f" }}> No new user</div>
              )}
            <div style={{ margin: "1em" }}>
              <Button
                onClick={this.importData}
                type="primary"
                style={{ width: "30%" }}
                disabled={
                  this.state.file.length === 0 ||
                  this.state.newUsers.length === 0
                }
              >
                importieren
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  saveStatus: state.usersReducer.saveStatus,
  message: state.usersReducer.message,
  status: state.usersReducer.status,
  users: state.usersReducer.users,
});
const WizardExcelImport = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedWizardExcelImport);
export default WizardExcelImport;
