import React, { Component } from "react";
import { connect } from "react-redux";
import { login } from "../../actions/login";
import "../login/login.css";

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email) => dispatch(login(email)),
  };
};

class ConnectedLoginMain extends Component {
  render() {
    
    return (
      <>
        <iframe
          title="iframe"
          src="https://app.appentum.pro/IMI_pano_tour/"
          style={{
            position: "absolute",
            left: "0px",
            width: "100%",
            top: "0px",
            height: "100%",
            border: "none",
          }}
        ></iframe>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  status: state.loginReducer.status,
});

const PublicSite = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLoginMain);
export default PublicSite;
