import { Modal, Form, message, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import welcomeText from "../../static/Welcome-text.svg";
import loginButton from "../../static/Button-Login.png";
import { login } from "../../actions/login";
import "./login.css";
import moment from "moment";

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email) => dispatch(login(email)),
  };
};

class ConnectedLoginMain extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    modalvisible: true,
    animationDelay: "3s",
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      email: event.target.value,
    });
  };

  handleSubmit = async () => {
    await this.props.login(this.state.email);
    if (this.props.status) {
      this.setState({
        modalvisible: false,
        animationDelay: "",
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "login",
        email: this.state.email,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      message.success("Einloggen ist Erfolgreich");
    } else {
      this.formRef.current.resetFields();
      this.setState({ email: "" });
      message.warning("Ungültige E-Mail-Adresse");
    }
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    };
    return (
      <>
        <Modal
          title={null}
          visible={this.state.modalvisible}
          footer={null}
          closable={false}
          width={430}
          height={405}
          centered
          style={{
            marginRight: "20px",
            animationDelay: this.state.animationDelay,
          }}
          forceRender
          bodyStyle={{ height: "413px" }}
        >
          <img
            style={{
              marginTop: "10px",
              height: "132px",
              width: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src={welcomeText}
            alt="welcomeText"
          />

          <div
            style={{
              textAlign: "center",
              fontFamily: "MarkPro",
              fontSize: "20px",
              marginTop: "25px",
            }}
          >
            Bitte geben Sie Ihre Email-Adresse an
          </div>

          <Form name="email" ref={this.formRef} {...formItemLayout}>
            <Form.Item
              validateStatus="warning"
              name="email"
              style={{
                // width: "47vw",
                width: "643px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Input
                style={{
                  background: "#EBEBEB",
                  height: "73.5px",
                  fontFamily: "MarkPro",
                  fontSize: "20px",
                  borderRadius: "5px",
                  marginLeft: "4px",
                  marginRight: "auto",
                }}
                id="waring"
                placeholder="Email"
                onChange={this.handleChange}
              />
            </Form.Item>
          </Form>

          <img
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              height: "51px",
              width: "auto",
              cursor: "pointer",
            }}
            src={loginButton}
            alt="loginButton"
            onClick={this.handleSubmit}
          />
        </Modal>
        <iframe
          title="iframe"
          src="https://app.appentum.pro/IMI_pano_tour/"
          style={{
            position: "absolute",
            left: "0px",
            width: "100%",
            top: "0px",
            height: "100%",
            border: "none",
          }}
        ></iframe>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  status: state.loginReducer.status,
});

const LoginMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLoginMain);
export default LoginMain;
