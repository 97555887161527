import "./App.css";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Spin } from "antd";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginMain from "./components/login/loginMain";
import WizardExcelImport from "./components/import/wizardExcelImport"
import PublicSite from "./components/public/public";

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/" component={LoginMain} />
          <Route exact path="/public" component={PublicSite} />
          <Route exact path="/v46sKpeP" component={WizardExcelImport}/>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
